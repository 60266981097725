import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
// import { CompanyPageComponent } from './OLD_company-page/company-page.component';
import { SupportedSymbolPageComponent } from './supported-symbol-page/supported-symbol-page.component';
import { DiscoverPageComponent } from './discover-page/discover-page.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { UserPageComponent } from './user-page/user-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { SubscriptionPageComponent } from './subscription-page/subscription-page.component';
import { PortfolioPageComponent } from './portfolio-page/portfolio-page.component';
import { LegalPageComponent } from './legal-page/legal-page.component';
import { SubscriptionSuccessPageComponent } from './subscription-page/subscription-success-page/subscription-success-page.component';
import { SubscriptionFailurePageComponent } from './subscription-page/subscription-failure-page/subscription-failure-page.component';
import { DisclaimerPageComponent } from './disclaimer-page/disclaimer-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { ComparisonPageComponent } from './comparison-page/comparison-page.component';
// import { CompanyPageTabsComponent } from './company-page-tabs/company-page-tabs.component';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';
import { CompanyPageSimpleComponent } from './company-page-simple/company-page-simple.component';

const routes: Routes = [
  {component: LandingPageComponent, path: ''},
  // {component: SupportedSymbolPageComponent, path: 'companies'},

  // {component: CompanyPageTabsComponent, path: 'company'},
  // {component: CompanyPageTabsComponent, path: 'company/:symbol'},

  {component: CompanyPageSimpleComponent, path: 'company'},
  {component: CompanyPageSimpleComponent, path: 'company/:symbol'},

  // {component: CompanyPageTabsComponent, path: 'company2'},
  // {component: CompanyPageTabsComponent, path: 'company2/:symbol'},
  {component: CompanyPageSimpleComponent, path: 'company2'},
  {component: CompanyPageSimpleComponent, path: 'company2/:symbol'},

  {component: DiscoverPageComponent, path: 'discover'},
  {component: NewsPageComponent, path: 'news'},
  {component: UserPageComponent, path: 'user'},
  {component: DashboardPageComponent, path: 'dashboard'},
  {component: SystemStatusComponent, path: 'system-status'},
  {component: SubscriptionPageComponent, path: 'subscribe'},
  {component: SubscriptionSuccessPageComponent, path: 'subscription-success'},
  {component: SubscriptionFailurePageComponent, path: 'subscription-failure'},
  {component: PortfolioPageComponent, path: 'portfolio/:portfolio_id'},
  {component: LegalPageComponent, path: 'legal'},
  {component: DisclaimerPageComponent, path: 'disclaimer'},
  {component: ContactUsPageComponent, path: 'contact-us'},
  {component: AboutUsPageComponent, path: 'about-us'},
  {component: BlogPageComponent, path: 'blog'},
  {component: ComparisonPageComponent, path: 'compare'},
  {component: UnsubscribePageComponent, path: 'unsubscribe'},
];

// /!\ Update the 'update_sitemap.py' scripts with new routes + prerender static files

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
