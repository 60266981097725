import { ChangeDetectionStrategy, Component, ElementRef, Inject, InjectionToken, Input, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { PillarData } from 'src/app/core/pillars.service';
import Chart, { registerables } from 'chart.js/auto';
import { isPlatformBrowser } from '@angular/common';

export interface DetailedPillarCategories {
  valuation: Array<PillarData>,
  profitability_growth: Array<PillarData>,
  financial_health: Array<PillarData>,
  liquidity_debt: Array<PillarData>,
  return_on_investment: Array<PillarData>
}

@Component({
  selector: 'app-detailed-radar-view',
  templateUrl: './detailed-radar-view.component.html',
  styleUrls: ['./detailed-radar-view.component.scss']
})
export class DetailedRadarViewComponent {
  @Input() data: DetailedPillarCategories|undefined

  ctx_chart: any
  chartjs_chart: Chart | undefined
  isBrowser = false

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>){
      this.isBrowser  = isPlatformBrowser(this.platformId);
  }
  
  ngOnInit(): void {
    Chart.register(...registerables)
    if(this.isBrowser){
      this.ctx_chart = this.elementRef.nativeElement.querySelector('#chart');
      this.chartjs_chart = new Chart<any>(this.ctx_chart, {
        type: 'radar',
        data: {
          labels: [
            'Valuation',
            'Financial Health',
            'Liquidity and Debt',
            'Profitability and Growth',
            'Return on Investment'
          ],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              display: false,
            },
            r: {
              display: true,
              min: 0,
              max: 10,
              ticks: {
                backdropPadding: {
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  width: 0,
                  height: 0
                }
              }
            },
            x: {
              display: false
            }
          },
          layout: {
            padding: 0,
            autoPadding: true
          },
          plugins: {
            title: {
              display: true,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false
            },
            
          }
        },
      })
      this.computeData()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const fieldName in changes) {
      if (changes.hasOwnProperty(fieldName) && !changes[fieldName].isFirstChange()) {
        // const fieldValue = this.data[fieldName];
        console.log('Change detected')
        this.computeData()
      }
    }
  }

  getRangedValueFromArrayOfPillars(arr: Array<PillarData>): number {
    const avg = (arr.reduce((prev, cur) => {
      if(cur.result){
        return prev + cur.weight
      } else {
        return prev
      }
    }, 0) / arr.reduce((prev, cur) => prev + cur.weight, 0))*10

    return avg
  }

  computeData(): void {
    if(this.chartjs_chart && this.chartjs_chart.data){
      this.chartjs_chart.data.datasets = []
    }
    if(this.data){
      this.addDataset([
        this.getRangedValueFromArrayOfPillars(this.data.valuation),
        this.getRangedValueFromArrayOfPillars(this.data.financial_health),
        this.getRangedValueFromArrayOfPillars(this.data.liquidity_debt),
        this.getRangedValueFromArrayOfPillars(this.data.profitability_growth),
        this.getRangedValueFromArrayOfPillars(this.data.return_on_investment)
      ])
    }
  }
  
  addDataset(values: number[]): void {
    if(!this.chartjs_chart || !this.chartjs_chart.data){
      return;
    }
    const avg = values.reduce((prev, cur) => prev + cur, 0)/values.length
    let backgroundColor = avg < 3 ? 'rgba(255, 99, 132, 0.2)' : avg < 6 ? 'rgba(255, 231, 108, 0.2)' : 'rgba(110, 252, 59, 0.2)'
    let borderColor = avg < 3 ? 'rgb(255, 99, 132)' : avg < 6 ? 'rgb(255, 231, 108)' : 'rgb(110, 252, 59)'
    this.chartjs_chart.data.datasets.push({
      label: 'Value',
      data: values,
      borderWidth: 2,
      fill: true,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      pointBackgroundColor: borderColor,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: borderColor,
      tension: 0.4,
      // borderJoinStyle: "bevel"

    })

    this.chartjs_chart.update()
    this.chartjs_chart.draw()
  }
}
