<div class="input-form mx-auto">
    <mat-form-field class="input-form-field">
        <mat-label class="input-field">Search</mat-label>
        <input  matInput placeholder="Company search" [(ngModel)]="symbol" [disabled]="_disabled">
        <mat-icon matPrefix>search</mat-icon>

        <mat-select class="search-bar-select mat-select-arrow" #singleSelect (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let item of search_list" [value]="item.symbol">
                {{item.symbol}}: {{item.company_name}}
            </mat-option>
        </mat-select>
        <button matSuffix class="action-button" mat-raised-button color="primary" (click)="onClick()" [disabled]="_disabled">
            <mat-icon>search</mat-icon>
            Analyze
        </button>
    </mat-form-field>
    <button matSuffix mat-icon-button color="primary" matTooltip="Random" (click)="onClickRandom()" [disabled]="_disabled">
        <mat-icon>shuffle</mat-icon>
    </button>
</div>
