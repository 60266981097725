<app-counter-banner></app-counter-banner>
<div class="header-sections pb-3">
    <mat-toolbar color="secondary" style="background-color: transparent;">
        <mat-toolbar-row>
            <button mat-button (click)="back()">
                <mat-icon>arrow_back_ios</mat-icon>
                Back
            </button>
            <button mat-icon-button [matMenuTriggerFor]="companyMore" style="margin-left: auto;">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #companyMore="matMenu">
                <button mat-menu-item (click)="goToComparison()">
                    <mat-icon>compare_arrows</mat-icon>
                    Compare with...
                </button>
            </mat-menu>
        </mat-toolbar-row>
    </mat-toolbar>

    <section>
        <div class="container">
            <div class="row py-0 mt-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-center">
                    <app-search-bar [symbol]="this.companySymbol!"></app-search-bar>
                </div>
            </div>
        </div>
    </section>
    
    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-xs-8">
                    <div>
                        <div [matTooltip]="userIsLogged() ? 'Add to favorite' : 'You need to be logged in'" style="width: 48px; height: 48px;">
                            <button [matTooltip]="userIsLogged() ? 'Add to favorite' : 'You need to be logged in'" mat-icon-button (click)="swapFavorite()" [disabled]="!userIsLogged()">
                                <mat-icon *ngIf="isFavorite()" class="favorite-icon">star</mat-icon>
                                <mat-icon *ngIf="!isFavorite()">star_border</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="mt-2">
                            <div class="nt-primary" style="font-family: Inter; font-size: 44px; font-weight: 800; line-height: 1.2;">
                                {{this.companySymbol}}
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="mb-0" style="font-family: Inter; font-size: 18px; color: rgb(107, 114, 128); line-height: 1.4;">
                                {{this.companyName}} 
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div class="justify-content-center text-center mt-4">
                        <div>
                            <span class="mx-auto highlight-title" matTooltip="VIP Score computed from Value Investing Pillars">
                                Global VIP Score
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <div *ngIf="this.vipScore" [style]="
                                        'background-color:'+getColorFromScore(this.vipScore)
                                        + '; border-radius: 30px; padding: 10px;'">
                                            <b>{{this.vipScore | number:'1.0-0'}}</b>/100
                                    </div>
                                </div>
                                <div class="col-2">
                                    <button mat-icon-button (click)="scrollToSection('valuation-section')">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <span *ngIf="!this.vipScore">-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isLoading && noDataFound">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <mat-card class="mb-0">
                            <mat-card-header>
                                <mat-card-title>
                                    Missing company? Suggest it!
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="mx-0">
                                <mat-form-field class="input-form-field">
                                    <mat-label class="input-field">Company name or symbol</mat-label>
                                    <input  matInput placeholder="Company symbol or name" [(ngModel)]="suggestedSymbol">
                                    <button matSuffix mat-raised-button color="primary" (click)="suggestCompany()">Suggest</button>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </section>    
</div>

<section class="mt-3" *ngIf="!noDataFound">
    <mat-tab-group>
        <mat-tab label="Main">
            <div class="container">
                <div class="row py-0 my-0 justify-content-center">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <mat-card class="my-2" *ngIf="latestBlogArticle">
                            <mat-card-header>
                                <mat-card-subtitle>Related blog posts</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <a [href]="latestBlogArticle.url" target="_blank">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="mb-2">
                                                <mat-icon style="position:relative; top: 6px; color: #ffdc50;">format_align_left</mat-icon>
                                                <span>{{latestBlogArticle.title}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="my-2">
                            <mat-card-header>
                                <mat-card-subtitle>Company profile</mat-card-subtitle>
                                <mat-card-title>{{this.companyName}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="mt-3" style="text-align: center;">
                                <span style="font-size: x-large; font-weight: 400;">
                                    {{this.latestStockPrice| currency}}

                                    <mat-icon *ngIf="stockPriceShortTermTrend > 0.4" style="color: #2ECC71;">trending_up</mat-icon>
                                    <mat-icon *ngIf="stockPriceShortTermTrend > -0.4 && stockPriceShortTermTrend < 0.4" style="color: #F1C40F;">trending_flat</mat-icon>
                                    <mat-icon *ngIf="stockPriceShortTermTrend <  -0.4" style="color: #E74C3C;">trending_down</mat-icon>
                                </span>
                                <p>Latest stock price</p>
                            </mat-card-content>

                            <mat-card-content>
                                <app-detailed-radar-view [data]="this.categoriesDetailed"></app-detailed-radar-view>
                            </mat-card-content>

                            <mat-card-content class="mt-3">
                                <span style="font-size: larger; font-weight: 500;">Metrics</span>
                                <div *ngFor="let metric of this.metrics">
                                    <span style="font-weight: 500;">{{metric.name}}:</span>
                                    <span> {{metric.value}}</span>
                                    <span style="position: relative; top: 7px">
                                        <mat-icon *ngIf="metric.trend != undefined && metric.trend > 0.4" [ngStyle]="{'color': '#2ECC71'}">trending_up</mat-icon>
                                        <mat-icon *ngIf="metric.trend != undefined && metric.trend > -0.4 && metric.trend < 0.4" [ngStyle]="{'color': '#F1C40F'}">trending_flat</mat-icon>
                                        <mat-icon *ngIf="metric.trend != undefined && metric.trend <  -0.4" [ngStyle]="{'color': '#E74C3C'}">trending_down</mat-icon>
                                    </span>
                                </div>
                            </mat-card-content>

                            <mat-card-content>
                                <div>
                                    <span style="font-weight: 500;">Sector: </span>{{this.companySector}}
                                </div>
                                <div>
                                    <span style="font-weight: 500;">Industry: </span>{{this.companyIndustry}}
                                </div>
                            </mat-card-content>

                            <mat-card-content>
                                <mat-card-header class="mb-1">
                                    <span matTooltip="Pillars representing internal strenghs of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                                        Strengths
                                    </span>
                                </mat-card-header>
                                <mat-card-content>
                                    <app-pillars-textual-strength [pillars]="this.pillarsData" [(short)]="this.shortHightlights"></app-pillars-textual-strength>
                                </mat-card-content>

                                <mat-card-header class="mb-1">
                                    <span matTooltip="Pillars representing internal weaknesses of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                                        Weaknesses
                                    </span>
                                </mat-card-header>
                                <mat-card-content>
                                    <app-pillars-textual-weakness [pillars]="this.pillarsData" [(short)]="this.shortHightlights"></app-pillars-textual-weakness>
                                </mat-card-content>

                                <mat-card-header class="mb-1">
                                    <span matTooltip="Pillars representing external opportunities of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                                        Opportunities
                                    </span>
                                </mat-card-header>
                                <mat-card-content style="text-align: center; position: relative;">
                                    <div *ngIf="!this.userIsLogged()" [class.div_blur]="!this.userIsLogged()"></div>
                                    <div *ngIf="!this.userIsLogged()" [class.div_blur_text]="!this.userIsLogged()">
                                        <span matTooltip="Login to access this feature">Login</span>
                                    </div>
                                    <app-pillars-textual-opportunity [pillars]="this.pillarsData" [(short)]="this.shortHightlights"></app-pillars-textual-opportunity>
                                </mat-card-content>

                                <mat-card-header class="mb-1">
                                    <span matTooltip="Pillars representing external threats of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                                        Threats
                                    </span>
                                </mat-card-header>
                                <mat-card-content style="text-align: center; position: relative;">
                                    <div *ngIf="!this.userIsLogged()" [class.div_blur]="!this.userIsLogged()"></div>
                                    <div *ngIf="!this.userIsLogged()" [class.div_blur_text]="!this.userIsLogged()">
                                        <span matTooltip="Login to access this feature">Login</span>
                                    </div>
                                    <app-pillars-textual-threat [pillars]="this.pillarsData" [(short)]="this.shortHightlights"></app-pillars-textual-threat>
                                </mat-card-content>
                            </mat-card-content>


                            <mat-card-content class="px-0">
                                <mat-accordion displayMode="flat">
                                    <mat-expansion-panel [expanded]="!isSmallScreen" class="mat-elevation-z0">
                                        <mat-expansion-panel-header>
                                            Company description
                                        </mat-expansion-panel-header>
                                        {{this.companyDescription}}
                                    </mat-expansion-panel>
                                </mat-accordion>
        
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
                        
                        <app-advertise [adName]="AdName.TradingView"></app-advertise>

                        <app-explore-more *ngIf="!isSmallScreen" [nbButtons]="4"></app-explore-more>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <mat-card class="my-2" id="pillars-section">
                            <app-premium-feature-icon class="top-right-corner"></app-premium-feature-icon> 
                            <mat-card-header>
                                <mat-card-subtitle>Value Investing Pillars analysis with detailed results</mat-card-subtitle>
                                <mat-card-title>Pillars details for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="px-0" style="position: relative;">
                                <!-- This premium has been disabled to test new design -->
                                <!-- <div *ngIf="!userHasPremium()" [class.div_blur]="!userHasPremium()"></div>
                                <div *ngIf="!userHasPremium()" [class.div_blur_text]="!userHasPremium()">
                                    Join Premium to access this feature
                                    <app-go-premium-button color="accent">Join Premium</app-go-premium-button>
                                </div> -->
                                <app-pillars [pillars]="pillarsData" [class.blur]="!userHasPremium()"></app-pillars>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>

                        <app-advertise [adName]="AdName.IBKR"></app-advertise>

                        <mat-card class="my-2" id="risk-section">
                            <mat-card-header>
                                <mat-card-subtitle>Explanation of current risks analyzed on {{this.companySymbol}}</mat-card-subtitle>
                                <mat-card-title>Risks for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="mx-auto">
                                    <app-pillars-textual-risk [pillars]="pillarsData"></app-pillars-textual-risk>
                                </div>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
        
                        <mat-card class="my-2" id="valuation-section">
                            <mat-card-header>
                                <mat-card-subtitle>Discounted Cashflow Analysis with assumptions</mat-card-subtitle>
                                <mat-card-title>DCF analysis for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-dcf-calculator 
                                    [sharesOutstanding]="this.latestShareOutstanding"
                                    [freeCashflow]="this.latestFreeCashflow"
                                    [priceEarning]="this.latestPriceEarnings"
                                    [priceFreeCashflow]="this.latestPriceFreeCashflow"
                                    [stockPrice]="this.latestStockPrice"
                                    [netIncome]="this.latestNetIncome"
                                    ></app-dcf-calculator>
                            </mat-card-content>
                        </mat-card>

                        <app-technical-pillars-list id="technical-section" [expanded]="false" [stockPriceData]="this.priceData"></app-technical-pillars-list>
        
                        <mat-card class="my-2" id="text-description">
                            <mat-card-header>
                                <mat-card-subtitle>Text description of the page</mat-card-subtitle>
                                <mat-card-title>Text description for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <button [cdkCopyToClipboard]="this.textDescription">Copy to clipboard</button>
                                <div [innerHTML]="this.textDescription">
                                </div>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
        
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Graphs">
            <div class="container">
                <div class="row py-0 my-0 justify-content-center">
                    <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                        <mat-card class="my-2" id="valuation-section">
                            <mat-card-header>
                                <mat-card-subtitle>NeoTradr Value Investing Analysis</mat-card-subtitle>
                                <mat-card-title>Value Investing Analysis for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div style="min-width: 300px;" class="mx-auto">
                                    <app-detailed-radar-view [data]="this.categoriesDetailed"></app-detailed-radar-view>
                                </div>
                                <div>
                                    <mat-accordion displayMode="flat">
                                        <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                                            <mat-expansion-panel-header>
                                                Legend
                                            </mat-expansion-panel-header>
                                            Company is analysed on five main perspective.
                                            <ul>
                                                <li>
                                                    <b>Valuation</b> assesses the company's worth compared to its current market price. A higher valuation indicates favorable value for investors relative to the current stock price.
                                                </li>
                                                <li>
                                                    <b>Financial Health</b> gauges the overall financial well-being of the company, focusing on aspects like stability, solvency, and long-term viability. A higher score reflects robust financial health.
                                                </li>
                                                <li>
                                                    <b>Liquidity and Debt</b> analyzes the company's debt levels and available cash. A higher rating suggests effective debt management and reduced financial risk.
                                                </li>
                                                <li>
                                                    <b>Profitability and Growth</b> examines the company's ability to generate profits and sustain growth. A higher score signifies strong performance, translating into favorable returns for shareholders.
                                                </li>
                                                <li>
                                                    <b>Return on Investment</b> evaluates the return generated for shareholders. A higher ROI indicates a more lucrative investment, showcasing the company's efficiency in delivering returns to its investors.
                                                </li>
                                            </ul>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="my-2" id="price-section">
                            <mat-card-header>
                                <mat-card-subtitle>Price</mat-card-subtitle>
                                <mat-card-title>Stock price for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-timeplot chart_title="Price" [time_values]="priceData.labels" [values]="priceData.values"></app-timeplot>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="my-2" id="revenue-section">
                            <mat-card-header>
                                <mat-card-subtitle>Revenue, Net Income and Free Cashflow data</mat-card-subtitle>
                                <mat-card-title>Revenue, Net Income and FCF for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-timeplot [chart_title]="['Revenue ($)', 'Net Income', 'Free CashFlow']" [time_values]="revenueData.labels" [values]="[revenueData.values, netIncomeData.values, freeCashflow.values]"></app-timeplot>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
        
                        <mat-card class="my-2" id="share-outstanding-section">
                            <mat-card-header>
                                <mat-card-subtitle>Number of shares</mat-card-subtitle>
                                <mat-card-title>Share outstanding for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-timeplot chart_title="Number of shares" [time_values]="shareOutstanding.labels" [values]="shareOutstanding.values"></app-timeplot>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
        
                        <mat-card class="my-2">
                            <mat-card-header>
                                <mat-card-subtitle>Total non current liabilities</mat-card-subtitle>
                                <mat-card-title>Long term liabilities for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-timeplot chart_title="Long term liabilities" [time_values]="longTermLiabilities.labels" [values]="longTermLiabilities.values"></app-timeplot>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card> 
        
                        <mat-card class="my-2" id="dividend-section">
                            <mat-card-header>
                                <mat-card-subtitle>Dividends</mat-card-subtitle>
                                <mat-card-title>Dividends for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-timeplot chart_title="Dividends ($)" [time_values]="dividends.labels" [values]="dividends.values"></app-timeplot>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
        
                        <mat-card class="my-2" id="roic-section">
                            <mat-card-header>
                                <mat-card-subtitle>Return On Invested Capital</mat-card-subtitle>
                                <mat-card-title>ROIC for {{this.companySymbol}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-timeplot [chart_title]="['ROIC (%)', 'ROE']" [time_values]="roicData.labels" [values]="[roicData.values, roeData.values]"></app-timeplot>
                            </mat-card-content>
                            <mat-card-content *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>

<section>
    <div class="container">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <app-explore-more></app-explore-more>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-12 my-4">
                Disclaimer: The information provided on this website is for educational and informational purposes only and should not be construed as financial advice. Users are solely responsible for any decisions or actions they take based on the information provided. This website makes no representations as to the accuracy, completeness, suitability, or validity of any information and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use. It is recommended that users consult with a qualified financial advisor or professional before making any investment decisions.
            </div>
        </div>
    </div>
</section>

<section>
    <app-footer></app-footer>
</section>
