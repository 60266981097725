import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card'
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSnackBarModule} from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatTabsModule } from '@angular/material/tabs'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MatButtonModule } from '@angular/material/button';
import { PillarDataDialog, PillarsComponent } from './components/pillars/pillars.component';
import { SymbolInputComponent } from './components/symbol-input/symbol-input.component';
import { SymbolListComponent } from './components/symbol-list/symbol-list.component';
// import { CompanyPageComponent } from './OLD_company-page/company-page.component';
import { SupportedSymbolPageComponent } from './supported-symbol-page/supported-symbol-page.component';
import { TimeplotComponent } from './components/timeplot/timeplot.component';
import { DiscoverPageComponent } from './discover-page/discover-page.component';
import { HighestScoreRankingComponent } from './components/highest-score-ranking/highest-score-ranking.component';
import { MailRegistrationButtonComponent, MailRegistrationDialog } from './components/mail-registration-button/mail-registration-button.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { ArticlesComponentComponent } from './components/articles-component/articles-component.component';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './components/login/login.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getAnalytics } from "firebase/analytics";
import { UserPageComponent } from './user-page/user-page.component';
import { SubscriptionOfferComponent } from './components/subscription-button/subscription-offer/subscription-offer.component';
import { SubscriptionButtonComponent } from './components/subscription-button/subscription-button.component';
import { SubscriptionOfferDialogComponent } from './components/subscription-button/subscription-offer-dialog/subscription-offer-dialog.component';
import { MainNewsComponent } from './components/main-news/main-news.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { PillarsChartComponent } from './components/pillars-chart/pillars-chart.component';
import { LoginRequiredComponent } from './components/login-required/login-required.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { MatSelectModule } from '@angular/material/select';
import { SystemStatusComponent } from './system-status/system-status.component';
import { SubscriptionPageComponent } from './subscription-page/subscription-page.component'
import { LoginService } from './core/login.service';
import { PillarsTextualRiskComponent } from './components/pillars-textual-risk/pillars-textual-risk.component';
import { PillarsDoughnutComponent } from './components/pillars-doughnut/pillars-doughnut.component';
import { HighestScoreBySectorComponent } from './components/highest-score-by-sector/highest-score-by-sector.component';
import { ExploreMoreComponent } from './components/explore-more/explore-more.component';
import { ScrollToTopDirective } from './scroll-to-top.directive';
import { PortfolioPageComponent } from './portfolio-page/portfolio-page.component';
import { LegalPageComponent } from './legal-page/legal-page.component';
import { SubscriptionSuccessPageComponent } from './subscription-page/subscription-success-page/subscription-success-page.component';
import { SubscriptionFailurePageComponent } from './subscription-page/subscription-failure-page/subscription-failure-page.component';
import { TechnicalPillarsListComponent } from './components/technical-pillars-list/technical-pillars-list.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { PremiumFeatureIconComponent } from './components/premium-feature-icon/premium-feature-icon.component';
import { LoginWindowComponent } from './components/login/login-window/login-window.component';
import { SimpleRadarViewComponent } from './components/radar-view/simple-radar-view/simple-radar-view.component';
import { PillarsTextualStrengthComponent } from './components/pillars-textual-strength/pillars-textual-strength.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { DisclaimerPageComponent } from './disclaimer-page/disclaimer-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { PillarsTextualWeaknessComponent } from './components/pillars-textual-weakness/pillars-textual-weakness.component';
import { PillarsTextualOpportunityComponent } from './components/pillars-textual-opportunity/pillars-textual-opportunity.component';
import { PillarsTextualThreatComponent } from './components/pillars-textual-threat/pillars-textual-threat.component';
import { SimpleProgressBarComponent } from './components/simple-progress-bar/simple-progress-bar.component';
import { DetailedRadarViewComponent } from './components/radar-view/detailed-radar-view/detailed-radar-view.component';
import { QaComponent } from './components/qa/qa.component';
import { GoPremiumButtonComponent } from './components/go-premium-button/go-premium-button.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ComparisonPageComponent } from './comparison-page/comparison-page.component';
import { CounterBannerComponent } from './counter-banner/counter-banner.component';
import { DcfCalculatorComponent } from './components/dcf-calculator/dcf-calculator.component';
// import { CompanyPageTabsComponent } from './company-page-tabs/company-page-tabs.component';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';
import { PriceTargetComponent } from './components/price-target/price-target.component';
import { PriceTargetListComponent } from './components/price-target-list/price-target-list.component';
import { MostAnalyzedCompaniesComponent } from './components/most-analyzed-companies/most-analyzed-companies.component';
import { CompanyPageSimpleComponent } from './company-page-simple/company-page-simple.component';
import { NewsTickerComponent } from './components/news-ticker/news-ticker.component';
import { AdvertiseComponent } from './components/advertise/advertise.component';
// const analytics = getAnalytics(app);

@NgModule({
  declarations: [
    AppComponent,
    ScrollToTopDirective,
    LandingPageComponent,
    PillarsComponent,
    PillarDataDialog,
    SymbolInputComponent,
    SymbolListComponent,
    // CompanyPageComponent,
    SupportedSymbolPageComponent,
    TimeplotComponent,
    DiscoverPageComponent,
    HighestScoreRankingComponent,
    MailRegistrationButtonComponent,
    MailRegistrationDialog,
    NewsPageComponent,
    ArticlesComponentComponent,
    LoginComponent,
    UserPageComponent,
    SubscriptionOfferComponent,
    SubscriptionButtonComponent,
    SubscriptionOfferDialogComponent,
    MainNewsComponent,
    DashboardPageComponent,
    PillarsChartComponent,
    LoginRequiredComponent,
    SearchBarComponent,
    SystemStatusComponent,
    SubscriptionPageComponent,
    PillarsTextualRiskComponent,
    PillarsDoughnutComponent,
    HighestScoreBySectorComponent,
    ExploreMoreComponent,
    ScrollToTopDirective,
    PortfolioPageComponent,
    LegalPageComponent,
    SubscriptionSuccessPageComponent,
    SubscriptionFailurePageComponent,
    TechnicalPillarsListComponent,
    PremiumFeatureIconComponent,
    LoginWindowComponent,
    SimpleRadarViewComponent,
    PillarsTextualStrengthComponent,
    FooterComponent,
    ContactUsPageComponent,
    DisclaimerPageComponent,
    AboutUsPageComponent,
    BlogPageComponent,
    PillarsTextualWeaknessComponent,
    PillarsTextualOpportunityComponent,
    PillarsTextualThreatComponent,
    SimpleProgressBarComponent,
    DetailedRadarViewComponent,
    QaComponent,
    GoPremiumButtonComponent,
    ComparisonPageComponent,
    CounterBannerComponent,
    DcfCalculatorComponent,
    // CompanyPageTabsComponent,
    UnsubscribePageComponent,
    PriceTargetComponent,
    PriceTargetListComponent,
    MostAnalyzedCompaniesComponent,
    CompanyPageSimpleComponent,
    NewsTickerComponent,
    AdvertiseComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatGridListModule,
    MatSelectModule,
    MatTabsModule,
    ClipboardModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    FontAwesomeModule
  ],
  providers: [LoginService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(faLibrary: FaIconLibrary){
    faLibrary.addIcons(faCrown)
  }
}
