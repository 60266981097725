import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, catchError, map, Observable, ObservableInput, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/compat/app'
import { getLocalStorageItem, setLocalStorageItem } from './local-storage-manager';

interface HttpRegistrationResult {
  statusCode: number,
  body: RegistrationResult
}
interface RegistrationResult {
  message: string,
  message_id: string
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  dataServiceUrl: string = environment.dataServiceUrl
  user: firebase.default.User | null = null
  idToken: string | null = null
  isLoggedIn = new BehaviorSubject(false)
  authState$: Observable<firebase.default.User | null>;

  constructor(private http: HttpClient, private firebase: AngularFireAuth) {
    const firebaseUser = getLocalStorageItem('firebase_user')
    const idToken = getLocalStorageItem('firebase_id_token')
    this.authState$ = this.firebase.authState; // This can be used to get user state (auth or not)
    this.authState$.subscribe(authState => {
      console.log('login.service: user update: ', authState)
      console.log('email?:', authState?.email)
      if(authState?.email){
        this.isLoggedIn.next(true)
      } else {
        this.isLoggedIn.next(false)
      }
    })
    if(firebaseUser && idToken){
      this.user = firebaseUser
      this.idToken = idToken
      this.isLoggedIn.next(true)
    } else {
      this.firebase.user.subscribe(user => {
        this.user = user;
        user?.getIdToken().then(idToken => {
          setLocalStorageItem("firebase_id_token", idToken, 60*30)
          this.idToken = idToken

          setLocalStorageItem('firebase_user', user, 60*30)
          if(user){
            this.isLoggedIn.next(true)
          } else {
            this.isLoggedIn.next(false)
          }
          console.log(`User authenticated and idToken fetched:${idToken} and ${JSON.stringify(user)}`)
        })
      })
    }
  }

  errorHandlerAny(err: any, caught: Observable<any>): ObservableInput<any> {
    console.error('LoginService Any error:', err, caught)
    return of(null)
  }

  registerUser(email: string): Observable<RegistrationResult> {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json'
      })
    };

    const requestData = {
      email: email
    }
    console.debug('Registration request data:', requestData)
    return this.http.post<HttpRegistrationResult>(
      `${this.dataServiceUrl}/register_user`,
      requestData, httpOptions)
      .pipe(
        map(x => x.body),
        catchError(this.errorHandlerAny)
      )
  }

  login(): void {
    this.resetAuthCache()
    this.loginWithGoogle()
  }

  loginWithGoogle(): void {
    this.resetAuthCache()
    this.firebase.signInWithPopup(new firebase.default.auth.GoogleAuthProvider())
  }

  loginWithEmailPassword(email: string, password: string): void {
    this.resetAuthCache()
    this.firebase.signInWithEmailAndPassword(email, password).then(userCredentials => {
      this.user = userCredentials.user
      console.log('User logged in user its credentials')
      console.log(userCredentials)
    }).catch(err => {
      this._handleFirebaseError(err)
    })
  }

  registerUserEmailPassword(email: string, passport: string): void {
    this.resetAuthCache()
    this.firebase.createUserWithEmailAndPassword(email, passport).then(userCredentials => {
      this.user = userCredentials.user
      console.log('User registered using its credentials succeed')
    }).catch(err => {
      this._handleFirebaseError(err)
    })
  }

  _handleFirebaseError(err: any){
    console.log(err);
    const errorCode = err.code;
    const errorMessage = err.message;
    if (errorCode == 'auth/weak-password') {
      alert('The password is too weak.');
    } else if(errorCode == 'auth/email-already-in-use'){
      alert('The email address is already in use by another account.')
    } else if(errorCode == 'auth/wrong-password'){
      alert('The password is invalid or the user does not have a password.')
    } else {
      alert(errorMessage);
    }
  }

  logout(): void {
    this.firebase.signOut().then(value => {
      setLocalStorageItem('firebase_user', null, 0)
      setLocalStorageItem('firebase_id_token', null, 0)
      this.isLoggedIn.next(false)
      this.user = null
    }).catch(err => {
      console.log(err)
    })
    this.resetAuthCache()
  }

  getIdToken(forceRefresh = false): Promise<string> {
    return new Promise((resolve, reject) => {
      if(forceRefresh){
        console.log('Forcing refresh of token...')
        this.firebase.user.subscribe(user => {
          this.user = user;
          user?.getIdToken().then(idToken => {
            setLocalStorageItem("firebase_id_token", idToken, 60*30)
            this.idToken = idToken
            setLocalStorageItem('firebase_user', user, 60*30)
            if(user){
              this.isLoggedIn.next(true)
            } else {
              this.isLoggedIn.next(false)
            }
            console.log(`User authenticated and idToken fetched:${idToken} and ${JSON.stringify(user)}`)
            return Promise.resolve(this.idToken)
          })
        })
      } else {
        if(this.idToken){
          resolve(this.idToken)
        } else {
          reject('Not authenticated')
        }
      }
    })
  }

  resetAuthCache(): void {
    setLocalStorageItem('firebase_user', null, 0)
    setLocalStorageItem('firebase_id_token', null, 0)
  }
}
