import { Component, ElementRef, Inject, InjectionToken, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { PillarData, PillarsService } from 'src/app/core/pillars.service';
import { isPlatformBrowser } from '@angular/common';
import { Chart, ChartDataset, ChartTypeRegistry, DoughnutController, Plugin, registerables } from 'chart.js';


@Component({
  selector: 'app-pillars-doughnut',
  templateUrl: './pillars-doughnut.component.html',
  styleUrls: ['./pillars-doughnut.component.scss']
})
export class PillarsDoughnutComponent {
  private _pillars: Array<PillarData> = []
  public vipScore: number|undefined
  @Input() 
  set pillars(value: Array<PillarData>){
    this._pillars = value
    this.vipScore = this.pillarsService.computeVipScore(this._pillars)
    this.loadData()
  }
  get pillars(): Array<PillarData>{
    return this._pillars
  }
  ctx_chart: any
  chartjs_chart: Chart | undefined
  chart_title: string = "Pillars"

  isBrowser = false

  constructor(
    private pillarsService: PillarsService,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>){
      this.isBrowser  = isPlatformBrowser(this.platformId);
  }

  getGreenColoGradient(percent: number): string {
    percent = Math.max(percent, 0)
    percent = Math.min(percent, 100)
    percent = 100 - percent
    const colorCodes = ['0','1','2','3','4','5','6','7','8','9','A','B'] // Going only to B to have more intense colors
    const colorChoosen = colorCodes[Math.round((( colorCodes.length - 1)/100)*percent)]
    return `#${colorChoosen}0FF${colorChoosen}0`
  }

  getRedColoGradient(percent: number): string {
    percent = Math.max(percent, 0)
    percent = Math.min(percent, 100)
    percent = 100 - percent
    const colorCodes = ['0','1','2','3','4','5','6','7','8','9','A','B'] // Going only to B to have more intense colors
    const colorChoosen = colorCodes[Math.round((( colorCodes.length - 1)/100)*percent)]
    return `#FF${colorChoosen}0${colorChoosen}0`
  }

  ngOnInit(): void {
    const plugin: Plugin = {
      id: 'text-inside-doughnut',
      beforeDraw: (chart: Chart) => {
        if ((<any>chart.config.options?.elements)?.center) {
          let ctx = chart.ctx;
          let centerConfig = (<any>chart.config.options?.elements)?.center;
          let fontStyle = centerConfig.fontStyle || 'Arial';
          // let txt = centerConfig.text;
          let txt = ''
          if(this.vipScore){
            txt = `${Math.round(this.vipScore)}/100`
          }
          // console.log('Drawing vipScore: ', txt)
          let color = centerConfig.color || '#000';
          let maxFontSize = centerConfig.maxFontSize || 75;
          let sidePadding = centerConfig.sidePadding || 20;

          let sidePaddingCalculated = (sidePadding / 100) * (50 * 2)
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          let stringWidth = ctx.measureText(txt).width;
          let elementWidth = (50 * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          let widthRatio = elementWidth / stringWidth;
          let newFontSize = Math.floor(30 * widthRatio);
          let elementHeight = (50 * 2);

          // Pick a new font size so it will not be larger than the height of label.
          let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          let minFontSize = centerConfig.minFontSize;
          let lineHeight = centerConfig.lineHeight || 25;
          let wrapText = false;

          if (minFontSize === undefined) {
              minFontSize = 20;
          }
          if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize;
              wrapText = true;
          }

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          if (!wrapText) {
              ctx.fillText(txt, centerX, centerY);
              return;
          }

          let words = txt.split(' ');
          let line = '';
          let lines = [];

          for (var n = 0; n < words.length; n++) {
              let testLine = line + words[n] + ' ';
              let metrics = ctx.measureText(testLine);
              let testWidth = metrics.width;
              if (testWidth > elementWidth && n > 0) {
                lines.push(line);
                line = words[n] + ' ';
              } else {
                line = testLine;
              }
          }

          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;

          for (let n = 0; n < lines.length; n++) {
              ctx.fillText(lines[n], centerX, centerY);
              centerY += lineHeight;
          }
          ctx.fillText(line, centerX, centerY);
        }
      }
    };

    Chart.register(...registerables)
    if(this.isBrowser){
      this.ctx_chart = this.elementRef.nativeElement.querySelector('#chart');
      this.chartjs_chart = new Chart<any>(this.ctx_chart, {
        type: 'doughnut',
        plugins: [plugin],
        data: {labels: [], datasets: []},
        options: {
          responsive: true,
          elements: {
            center: {
              // text: 'Score here',
              // color: '#1840F7',
              fontStyle: 'Roboto', // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25 // Default is 25 (in px), used for when text wraps
            }
          },
          plugins: {
            legend: {
              // position: 'top',
              display: false
            }
          },
          title: {
            display: true,
            text: 'Test Doughnut'
          }
        },
      })
      this.loadData()
    }
  }
  
  loadData(): void {
    if(this.chartjs_chart){
      const labels = this._pillars.map(x => `Pillar ${x.id}`)
      const data = this._pillars.map(x => x.result ? 1+x.weight : 1+x.weight)
      // const valueColors = values.map(v => this.getGreenColoGradient(v))

      const backgroundColors = this._pillars.map(x => {
        if(x.result){
          return this.getGreenColoGradient((x.weight/2)*100)
        } else {
          return this.getRedColoGradient((x.weight/2)*100)
        }
      });

      this.chartjs_chart.config.data.labels = labels
      let dataset: ChartDataset = {
        label: 'Weight',
        data: data,
        backgroundColor: backgroundColors
      }
      this.chartjs_chart.config.data.datasets = [dataset]
      this.chartjs_chart.update()
      this.chartjs_chart.draw()
    }
  }
}
