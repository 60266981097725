import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GroupedNewsGroup, IntelligenceService } from '../../core/intelligence.service';

@Component({
  selector: 'app-main-news',
  templateUrl: './main-news.component.html',
  styleUrls: ['./main-news.component.scss']
})
export class MainNewsComponent implements OnInit {
  @Input() short: boolean = false
  @Input() n_data: number = 10
  @Input() full_page: boolean = false
  @Input() useCarousel: boolean = false
  @ViewChild('carouselContainer') carouselContainer: ElementRef | undefined;

  grouped_news: Array<GroupedNewsGroup> = [];
  isLoading: boolean = true

  currentIndex: number = 0;
  intervalId: any;


  constructor(private intelligence: IntelligenceService) { }

  ngOnInit(): void {
    this.isLoading = true
    if(this.useCarousel){
      this.startAutoSlide();
    }
    this.intelligence.getGroupedNews().subscribe(grouped_news_result => {
      try {
        if(grouped_news_result && grouped_news_result.groups){
          let grouped_news = grouped_news_result.groups
          grouped_news.forEach(group => {
            group.articles_data.sort((a, b) => {
              return a.article.datetime > b.article.datetime ? -1 : 1
            })
          });

          grouped_news.sort((a, b) => {
            return a.articles_data[0].article.datetime > b.articles_data[0].article.datetime ? -1 : 1
          })
          grouped_news = grouped_news.filter((v, i) => i < this.n_data)
          this.grouped_news = grouped_news // Assign only at the end make the UI refresh correctly
          this.isLoading = false
        } else {
          this.grouped_news = []
          this.isLoading = false
        }
        console.log('N Grouped News: ', this.grouped_news.length)
      } catch(err) {
        console.warn('Error when sorting data: ', err);
        this.isLoading = false
      }
    })
  }

  ngOnDestroy(): void {
    this.stopAutoSlide();
  }

  startAutoSlide(): void {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 5000); // Change slide every 3 seconds
  }

  stopAutoSlide(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  previousSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + this.grouped_news.length) % this.grouped_news.length;
  }

  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.grouped_news.length;
  }
}
