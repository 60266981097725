
<section>
    <div class="container">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-center">
                <div class="mt-5">
                    <div class="nt-primary my-4" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
                        Profile
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="!this.ntUser">
    <mat-spinner></mat-spinner>
</div>
<mat-card class="user-card text-center" *ngIf="this.ntUser">
    <img *ngIf="this.ntUser.picture"  [src]="this.ntUser.picture" alt="Profile picture" class="profile-picture mx-auto mt-2" loading="lazy">
    <mat-icon *ngIf="!this.ntUser.picture" class="profile-picture mx-auto mt-2" style="font-size: xx-large;">account_circle</mat-icon>
    <mat-card-content>
        <mat-card-title>{{ this.ntUser.name ? this.ntUser.name : this.ntUser.email }}</mat-card-title>
        <mat-card-subtitle>{{ this.ntUser.email }}</mat-card-subtitle>
    </mat-card-content>
    <mat-card-content>
        <mat-checkbox [(ngModel)]="user_settings.neotradr_receive_weekly_report">Receive Weekly Report (best companies report and more)</mat-checkbox>
        <mat-checkbox [(ngModel)]="user_settings.neotradr_receive_daily_report">Receive Daily Report (best companies report and more)</mat-checkbox>
        <mat-checkbox [disabled]="true">Enable AI analysis of companies</mat-checkbox>
        <mat-checkbox [disabled]="true">Access to precise timing data to buy/sell stocks</mat-checkbox>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="accent" (click)="saveSettings()">
            <mat-icon>save</mat-icon>
            Save
        </button>
    </mat-card-actions>
</mat-card>

<mat-card class="user-card text-center" *ngIf="this.ntUser && this.neotradr_experimental.enable_subscription">
    <mat-card-header>
        <button mat-icon-button style="position: absolute; right: 10px; top: 10px;" (click)="refreshSubscription()">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-card-title>Your products</mat-card-title>
        <mat-card-subtitle>Products for {{ this.ntUser.email }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-spinner *ngIf="this.subscribedProduct === undefined"></mat-spinner>
        <mat-list *ngIf="this.subscribedProduct">
            <mat-list-item *ngFor="let product of subscribedProduct">
                <mat-accordion displayMode="flat">
                    <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                        <mat-expansion-panel-header>
                            Premium Subscription <app-premium-feature-icon class="mx-2"></app-premium-feature-icon>
                        </mat-expansion-panel-header>
                        <span matListItemTitle>You have a: {{product.object}}</span>
                        <span matListItemLine>Your customer ID: {{product.customer}}</span>
                        <span matListItemLine>Start date: {{product.start_date*1000 | date}}</span>
                        <span matListItemLine>Status: {{product.status}}</span>
                        <span matListItemLine *ngIf="product.canceled_at"><b>Canceled on: {{product.canceled_at*1000 | date}}</b></span>
                        <span matListItemLine *ngIf="product.cancel_at">Valid until: {{product.cancel_at*1000 |date}}</span>        
                    </mat-expansion-panel>
                </mat-accordion>
                
            </mat-list-item>
        </mat-list>
        <div *ngIf="!this.subscribedProduct || this.subscribedProduct.length == 0">
            <app-subscription-button>Upgrade to Next Gen Investor</app-subscription-button>
            <div>You have currently no products</div>
        </div>
        
    </mat-card-content>
    <mat-card-actions *ngIf="this.subscribedProduct && this.subscribedProduct.length > 0">
        <button mat-button (click)="this.manageSubscription()">Manage subscription</button>
    </mat-card-actions>
</mat-card>

<mat-card class="user-card text-center" *ngIf="this.ntUser">
    <button mat-raised-button color="warn" (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        Logout
    </button>
</mat-card>
<app-explore-more></app-explore-more>
<app-footer></app-footer>