
<div class="header-sections">
    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-center">
                    <div class="mt-4">
                        <div class="nt-primary my-3" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
                            Dashboard
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="getWelcomeMessage()" class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-center">
                    <div class="mt-1">
                        <div class="nt-primary my-0" style="font-family: Inter; font-size: 24px; font-weight: 700; line-height: 1.2;">
                            {{getWelcomeMessage()}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="my-4">
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                    <div class="nt-primary" style="font-family: Inter; font-size: 38px; font-weight: 800; line-height: 1.2;">
                        Company search
                    </div>
                    <app-search-bar></app-search-bar>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                    <div class="nt-primary" style="font-family: Inter; font-size: 38px; font-weight: 800; line-height: 1.2;">
                        Quick access
                    </div>
                    <div class="m-3">
                        <div class="mx-3 mt-1" style="float: left;">
                            <a mat-raised-button color="accent" href="/discover">
                                <div class="cta-big-text">
                                    <mat-icon>stars</mat-icon>
                                    <span class="aligned-with-icon">Best ranked companies</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="m-3">
                        <div class="mx-3 mt-1" style="float: left;">
                            <a mat-raised-button color="accent" href="/news">
                                <div class="cta-big-text">
                                    <mat-icon>article</mat-icon>
                                    <span class="aligned-with-icon">Business news</span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="m-3">
                        <div class="mx-3 mt-1" style="float: left;">
                            <a mat-raised-button color="accent" href="/compare">
                                <div class="cta-big-text">
                                    <mat-icon>compare_arrows</mat-icon>
                                    <span class="aligned-with-icon">Comparison Tool</span>
                                </div>
                            </a>
                        </div>
                    </div>
    
                    <div class="m-3">
                        <div class="mx-3 mt-1" style="float: left;">
                            <a mat-raised-button color="accent" [matMenuTriggerFor]="portfolio_menu">
                                <div class="cta-big-text">
                                    <mat-icon>bar_chart</mat-icon>
                                    <span class="aligned-with-icon">Portolio analysis</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <mat-menu #portfolio_menu="matMenu">
                        <div *ngFor="let link of links">
                            <button mat-menu-item (click)="goTo(link.page)">
                                <mat-icon>bar_chart</mat-icon>
                                {{link.name}}
                            </button>
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
    </section>
    
</div>

<section class="my-4">
    <div class="container">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
                <div class="nt-primary" style="font-family: Inter; font-size: 38px; font-weight: 800; line-height: 1.2;">
                    Favorite list
                </div>
                <div>
                    <mat-progress-bar *ngIf="this.isLoadingFavorite" mode="indeterminate"></mat-progress-bar>
                    <table mat-table matSort [dataSource]="dataSource">
                        <ng-container matColumnDef="favicon">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon class="favorite-icon">stars</mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="symbol">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol"> Symbol</th>
                          <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                        </ng-container>

                        <ng-container matColumnDef="company_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="stock_price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by price"> Last price</th>
                            <td mat-cell *matCellDef="let element">{{element.stock_price | number}}</td>
                        </ng-container>

                        <ng-container matColumnDef="stock_price_trend">
                            <th mat-header-cell *matHeaderCellDef> Weekly trend</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon *ngIf="element.short_term_trend > 0.4" style="color: #2ECC71;">trending_up</mat-icon>
                                <mat-icon *ngIf="element.short_term_trend > -0.4 && element.short_term_trend < 0.4" style="color: #F1C40F;">trending_flat</mat-icon>
                                <mat-icon *ngIf="element.short_term_trend <  -0.4" style="color: #E74C3C;">trending_down</mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="vip_score">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by score"> VIP Score</th>
                            <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color':getColorFromScore(element.score)}"> {{element.score | number:'1.0-0'}}/100</td>
                        </ng-container>
                
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Go to company</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button (click)="goToCompanyPage(element.symbol)"><mat-icon>keyboard_arrow_right</mat-icon></button>
                            </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row (click)="onRowSelected(row)"
                            [class.row-is-clicked]="selectedRow && row == selectedRow"
                            *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    
                    <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons
                    aria-label="Select page of periodic elements"></mat-paginator>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
                <div class="nt-primary" style="font-family: Inter; font-size: 38px; font-weight: 800; line-height: 1.2;">
                    Price targets
                </div>
                <app-price-target-list></app-price-target-list>
            </div>
        </div>
    </div>
</section>


<section class="my-4">
    <div class="container">
        <div class="row py-0 my-0 justify-content-center ">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="nt-primary" style="font-family: Inter; font-size: 38px; font-weight: 800; line-height: 1.2;">
                    Business news
                </div>
                <app-main-news [short]="false" [n_data]="10"></app-main-news>
            </div>
        </div>
    </div>
</section>

<section>
    <app-explore-more></app-explore-more>
</section>

<section>
    <app-footer></app-footer>
</section>
