

<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row">
    <div *ngIf="!useCarousel">
        <div
        *ngFor="let group_of_news of grouped_news"
        class="col-xs-12 col-sm-12 col-md-12 my-2 px-1"
        [ngClass]="full_page ? 'col-xl-6 col-lg-6' : 'col-lg-12 col-xl-12'">
            <mat-card>
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title>
                            <a target="_blank" href="{{group_of_news.articles_data[0].article.url}}">
                                {{group_of_news.articles_data[0].article.title}}
                            </a>
                        </mat-card-title>
                        <a href="{{group_of_news.articles_data[0].article.url}}" target="_blank">
                            <img mat-card-sm-image src="{{group_of_news.articles_data[0].article.img}}" class="card-img" alt="Image describing the news" loading="lazy">
                        </a>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>
                    <a target="_blank" href="{{group_of_news.articles_data[0].article.url}}">{{group_of_news.articles_data[0].article.content | json}}</a>
                    <p>Publication date: {{group_of_news.articles_data[0].article.datetime | date}}</p>
                </mat-card-content>
                
                <mat-card-content>
                    Polarity analysis: {{(group_of_news.articles_data[0].article.sentiment.polarity + 1)*50 | number}}%
                    <!-- Subjectivity analysis: {{group_of_news.articles_data[0].article.sentiment.subjectivity*100 | number}}% -->
                    <!-- <app-simple-progress-bar [percentage]="(group_of_news.articles_data[0].article.sentiment.polarity+1)*50"></app-simple-progress-bar> -->
                </mat-card-content>
                
                <mat-accordion>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="!short">
                        <mat-expansion-panel-header >
                            <mat-panel-title>
                                <div class="secondary-link">
                                    Related articles
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                        <mat-card-content>
                            <hr>
                            <div *ngFor="let article_match of group_of_news.articles_data; let i = index">
                                <div *ngIf="i < 5">
                                    <a class="secondary-link" target="_blank" href="{{article_match.article.url}}">
                                        {{article_match.article.title}}
                                    </a>
                                </div>
                            </div>
                        </mat-card-content>

                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
        </div>
    </div>
    <div *ngIf="useCarousel">
        <div class="carousel-container">
            <div
            class="carousel-slide"
            *ngFor="let group_of_news of grouped_news; let i = index"
            [ngClass]="{'active': i === currentIndex, 'prev': i === (currentIndex - 1 + grouped_news.length) % grouped_news.length, 'next': i === (currentIndex + 1) % grouped_news.length}">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title-group>
                            <mat-card-title>
                                <a target="_blank" href="{{group_of_news.articles_data[0].article.url}}">
                                    {{group_of_news.articles_data[0].article.title}}
                                </a>
                            </mat-card-title>
                            <a href="{{group_of_news.articles_data[0].article.url}}" target="_blank">
                                <img mat-card-sm-image src="{{group_of_news.articles_data[0].article.img}}" class="card-img" alt="Image describing the news" loading="lazy">
                            </a>
                        </mat-card-title-group>
                    </mat-card-header>
                    <mat-card-content>
                        <a target="_blank" href="{{group_of_news.articles_data[0].article.url}}">{{group_of_news.articles_data[0].article.content | json}}</a>
                        <p>Publication date: {{group_of_news.articles_data[0].article.datetime | date}}</p>
                    </mat-card-content>
                    
                    <mat-card-content>
                        Polarity analysis: {{(group_of_news.articles_data[0].article.sentiment.polarity + 1)*50 | number}}%
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="carousel-controls">
                <button (click)="previousSlide()"><</button>
                <button (click)="nextSlide()">></button>
            </div>
        </div>
    </div>
</div>

